const root = process.env.GATSBY_API_ROOT || "http://localhost:18001";

const api = {
  contact: (params) =>
    fetch(`${root}/v1/auth/contact`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    }),
};

export default api;
